import { useMutation, useQuery, UseQueryOptions, UseMutationOptions, useQueries } from "@tanstack/react-query";
import * as LandfillRequests from "./service/landfill";
import {
  AddLandfillRoleDefinitionRequest,
  ApisRoleDefinition,
  AsyncOperationReportOnly,
  AsyncOperationReportType,
  CreateLandfillReportReceiverRequest,
  CreateLandfillRequest,
  DeviceSummaryWithMeta,
  LandfillCharts,
  LandfillMonthlyDeviceAveragesResponse,
  LandfillPerformanceConfig,
  LandfillReportReceiverT,
  LandfillStartCalibrationRequest,
  LandfillStartCalibrationResponse,
  LandfillSummary,
  LandfillT,
  UpdateLandfillRequest
} from "@apis/types";

import { queryClient } from "./";
import { User } from "@auth0/types";

////////// QUERIES //////////
export const useGetLandfills = (allowHidden = false, options?: Omit<UseQueryOptions<LandfillT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfills", allowHidden],
  queryFn: () => LandfillRequests.getAllLandfills(allowHidden),
  staleTime: 30 * 1000,
  ...options
});

export const useGetLandfill = (landfillID: string, options?: Omit<UseQueryOptions<LandfillT>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill", landfillID],
  queryFn: () => LandfillRequests.getLandfill(landfillID),
  ...options
});

export const useGetLandfillSummary = (landfillID: string, options?: Omit<UseQueryOptions<LandfillSummary>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-summary", landfillID],
  queryFn: () => LandfillRequests.getLandfillSummary(landfillID),
  ...options
});

export const useGetLandfillMonthlyDeviceAverages = (landfillID: string, startDate: string, endDate: string, options?: Omit<UseQueryOptions<LandfillMonthlyDeviceAveragesResponse>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-monthly-averages", landfillID, startDate, endDate],
  queryFn: () => LandfillRequests.getLandfillMonthlyDeviceAverages(landfillID, startDate, endDate),
  staleTime: 10000,
  ...options
});


export const useGetLandfillDeviceSummaries = (landfillID: string, options?: Omit<UseQueryOptions<DeviceSummaryWithMeta[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-device-summaries", landfillID],
  queryFn: () => LandfillRequests.getLandfillDeviceSummaries(landfillID),
  ...options
});

export const useGetLandfillCharts = (landfillID: string, start: string, end: string, options?: Omit<UseQueryOptions<LandfillCharts>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-charts", landfillID, start, end],
  queryFn: () => LandfillRequests.getLandfillCharts(landfillID, start, end),
  ...options
});

export const useGetDailyLandfillReportReceivers = (landfillID: string, options?: Omit<UseQueryOptions<LandfillReportReceiverT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-daily-report-receivers", landfillID],
  queryFn: () => LandfillRequests.getDailyLandfillReportReceivers(landfillID),
  ...options
});

export const useGetLandfillKMLUrl = (landfillID: string, options?: Omit<UseQueryOptions<string | false>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-kml-url", landfillID],
  queryFn: () => LandfillRequests.getKmlUrl(landfillID),
  ...options
});

export const useGetLandfillReportsByType = (landfillID: string, reportType: AsyncOperationReportType, options?: Omit<UseQueryOptions<AsyncOperationReportOnly[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-reports-by-type", landfillID, reportType],
  queryFn: () => LandfillRequests.getLandfillReportsByType(landfillID, reportType),
  staleTime: 60 * 1000,
  ...options
});

export const useGetLandfillRoles = (landfillID: string, options?: Omit<UseQueryOptions<ApisRoleDefinition[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["landfill-roles", landfillID],
  queryFn: () => LandfillRequests.getLandfillRolDefs(landfillID),
  ...options
});



////////// MUTATIONS //////////
export const useCreateLandfill = (options?: UseMutationOptions<LandfillT, Error, CreateLandfillRequest>) => useMutation({
  mutationKey: ["create-landfill"],
  mutationFn: (body) => LandfillRequests.createLandfill(body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfills", true], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateLandfill = (landfillID: string, options?: UseMutationOptions<LandfillT, Error, UpdateLandfillRequest>) => useMutation({
  mutationKey: ["update-landfill", landfillID],
  mutationFn: (body) => LandfillRequests.updateLandfill(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill", args[0].id], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteLandfill = (landfillID: string, options?: UseMutationOptions<LandfillT>) => useMutation({
  mutationKey: ["delete-landfill", landfillID],
  mutationFn: () => LandfillRequests.deleteLandfill(landfillID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfills", true], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useAddLandfillRoles = (landfillID: string, options?: UseMutationOptions<ApisRoleDefinition, Error, AddLandfillRoleDefinitionRequest>) => useMutation({
  mutationKey: ["add-landfill-role", landfillID],
  mutationFn: (body) => LandfillRequests.addLandfillRoleDefinition(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-roles", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteLandfillRoles = (landfillID: string, options?: UseMutationOptions<User, Error, string>) => useMutation({
  mutationKey: ["delete-landfill-role", landfillID],
  mutationFn: (roleDefinitionID) => LandfillRequests.deleteLandfillRoleDefinition(landfillID, roleDefinitionID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-roles", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useRestoreValvePositions = (landfillID: string, options?: UseMutationOptions<Record<string, { status: boolean, message: string }>, Error, string>) => useMutation({
  mutationKey: ["restore-valve-positions", landfillID],
  mutationFn: (date) => LandfillRequests.restoreValvePositions(landfillID, date),
  ...options
});

export const useDownloadLandfillAverages = (landfillID: string, start: string, end: string, options?: UseMutationOptions<void>) => useMutation({
  mutationKey: ["download-landfill-averages", landfillID, start, end],
  mutationFn: () => LandfillRequests.downloadLandfillAverages(landfillID, start, end),
  ...options
});

export const useAddDailyLandfillReportReceiver = (landfillID: string, options?: UseMutationOptions<LandfillReportReceiverT, Error, CreateLandfillReportReceiverRequest>) => useMutation({
  mutationKey: ["add-daily-landfill-report-receiver", landfillID],
  mutationFn: (body) => LandfillRequests.addDailyLandfillReportReceiver(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-daily-report-receivers", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeactivateDailyLandfillReportReceiver = (landfillID: string, options?: UseMutationOptions<LandfillReportReceiverT, Error, string>) => useMutation({
  mutationKey: ["deactivate-daily-landfill-report-receiver", landfillID],
  mutationFn: (email) => LandfillRequests.deactivateDailyLandfillReportReceiver(landfillID, email),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-daily-report-receivers", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useDeleteDailyLandfillReportReceiver = (landfillID: string, options?: UseMutationOptions<{ message: string }, Error, string>) => useMutation({
  mutationKey: ["delete-daily-landfill-report-receiver", landfillID],
  mutationFn: (email) => LandfillRequests.deleteDailyLandfillReportReceiver(landfillID, email),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-daily-report-receivers", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useLandfillSampleMultipleDevices = (landfillID: string, options?: UseMutationOptions<{ failed: string[] }, Error, string[]>) => useMutation({
  mutationKey: ["landfill-sample-multiple-device", landfillID],
  mutationFn: (devices) => LandfillRequests.sampleMultipleDevices(landfillID, devices),
  ...options
});

export const useLandfillMultipleCalibration = (landfillID: string, options?: UseMutationOptions<LandfillStartCalibrationResponse[] | string, Error, LandfillStartCalibrationRequest[]>) => useMutation({
  mutationKey: ["landfill-calibration-multiple", landfillID],
  mutationFn: (devices) => LandfillRequests.startMultipleCalibration(landfillID, devices),
  ...options
});

export const useExportLandfillSchedulers = (landfillID: string, options?: UseMutationOptions<void>) => useMutation({
  mutationKey: ["export-landfill-schedulers", landfillID],
  mutationFn: () => LandfillRequests.exportLandfillSchedulers(landfillID),
  ...options
});

export const useImportLandfillSchedulers = (landfillID: string, options?: UseMutationOptions<{ failed: string[] }, Error, File>) => useMutation({
  mutationKey: ["import-landfill-schedulers", landfillID],
  mutationFn: (file) => LandfillRequests.importLandfillSchedulers(landfillID, file),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-devices", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateLandfillKML = (landfillID: string, options?: UseMutationOptions<string, Error, File>) => useMutation({
  mutationKey: ["update-landfill-kml", landfillID],
  mutationFn: (file) => LandfillRequests.updateKml(landfillID, file),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-kml-url", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useGetLandfillReportByType = (landfillID: string, options?: UseMutationOptions<void, Error, { startDate: string, endDate: string, reportDate: string, reportType: AsyncOperationReportType }>) => useMutation({
  mutationKey: ["landfill-report-by-type", landfillID],
  mutationFn: (body) => LandfillRequests.getLandfillReportByType(landfillID, body.startDate, body.endDate, body.reportDate, body.reportType),
  ...options
});

export const useCreateLandfillReportByType = (landfillID: string, reportType: AsyncOperationReportType, options?: UseMutationOptions<void, Error, { startDate: string, endDate: string }>) => useMutation({
  mutationKey: ["create-landfill-report", landfillID, reportType],
  mutationFn: ({ startDate, endDate }) => LandfillRequests.createLandfillReportByType(landfillID, startDate, endDate, reportType),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-reports-by-type", landfillID, reportType], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useExportLandfillDevices = (landfillID: string, options?:  UseMutationOptions<void>) => useMutation({
  mutationKey: ["export-landfill-devices", landfillID],
  mutationFn: () => LandfillRequests.exportLandfillDevices(landfillID),
  ...options
});

export const useImportLandfillDevices = (landfillID: string, options?: UseMutationOptions<{ failed: string[] }, Error, File>) => useMutation({
  mutationKey: ["import-landfill-devices", landfillID],
  mutationFn: (file) => LandfillRequests.importLandfillDevices(landfillID, file),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-devices", landfillID], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useMutateLandfillDeviceSummaries = (options?: Omit<UseMutationOptions<DeviceSummaryWithMeta[], Error, string>, "queryKey" | "queryFn">) => useMutation({
  mutationKey: ["landfill-device-summaries"],
  mutationFn: (lf) => LandfillRequests.getLandfillDeviceSummaries(lf),
  ...options
});

export const useImportLandfillPreAutomationData = (landfillID: string, options?: UseMutationOptions<{ message: string }, Error, { file: File, fileTimezone: string }>) => useMutation({
  mutationKey: ["import-landfill-pre-automation-data", landfillID],
  mutationFn: ({ file, fileTimezone }) => LandfillRequests.importLandfillPreAutomationData(landfillID, file, fileTimezone),
  ...options,
  onSuccess: (...args) => {
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateLandfillPerformanceConfigs = (landfillID: string, options?: UseMutationOptions<LandfillPerformanceConfig, Error, LandfillPerformanceConfig>) => useMutation({
  mutationKey: ["import-landfill-pre-automation-data", landfillID],
  mutationFn: (body) => LandfillRequests.updateLandfillPerformanceConfigs(landfillID, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["landfill-monthly-averages", landfillID], exact: false })
    options?.onSuccess && options?.onSuccess(...args)
  }
});
