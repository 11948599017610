export * from "./lib/device";
export * from "./lib/landfill";
export * from "./lib/asyncOperations";

export * from "./lib/alertParameters";
export * from "./lib/scheduler";
export * from "./lib/deviceStatuses";
export * from "./lib/controlAlgorithmSettings";
export * from "./lib/deviceCalibration";
export * from "./lib/metadata";
export * from "./lib/roles";
export * from "./lib/userActivity";
export * from "./lib/particle";
export * from "./lib/misc";
export * from "./lib/notifications"
export * from "./lib/verificationCode"
export * from "./lib/chartComments"

export const FEATURE_VERSIONS = {
  "MONTHLY_DEVICE_AVERAGES": 2
}

export const PREFERRED_UNIT = {
  "SCF": "SCF",
  "CO2E": "Co2e",
  "MMBTU": "mmBTU",
  "RNG": "RNG"
} as const;

export const PREFERRED_UNITS = [
  PREFERRED_UNIT.SCF,
  PREFERRED_UNIT.CO2E,
  PREFERRED_UNIT.MMBTU,
  PREFERRED_UNIT.RNG,
] as const;
