import { ChartCommentT } from "@apis/types";
import { max, min } from "mathjs";
import { DateTime } from "luxon";

const ChartCommentPlugin = {
  id: "chartCommentPlugin",
  lineWidth: 7,
  rowHeight: 10,
  totalCommentHeight: 0,
  lines: [],
  beforeDraw(chart) {
    const pluginOptions = chart.config.options.plugins?.chartCommentPlugin || {};
    if (!pluginOptions.data?.length) return;
    const comments: ChartCommentT[] = pluginOptions.data
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const timeScale = chart.scales.x;
    const alignedComments: ChartCommentT[][] = comments.reduce((acc, comment) => {
      let placed = false
      for (const row of acc) {
        const isOverlapping = row.some((rowComment) =>
          (rowComment.startDate <= comment.startDate && comment.startDate <= rowComment.endDate) ||
          (rowComment.startDate <= comment.endDate && comment.endDate <= rowComment.endDate) ||
          (!rowComment.endDate && comment.startDate <= rowComment.startDate && rowComment.startDate <= comment.endDate)
        )
        if (!isOverlapping) {
          row.push(comment)
          placed = true
          break;
        }
      }
      if (!placed) acc.push([comment])
      return acc;
    }, [[]] as ChartCommentT[][])
    this.totalCommentHeight = alignedComments.length * this.rowHeight + this.rowHeight
    const lines = [];
    alignedComments.forEach((row, rowNumber) => {
      row.forEach((comment) => {
        const startX = timeScale.getPixelForValue(new Date(comment.startDate))
        const endX = timeScale.getPixelForValue(new Date(comment.endDate || comment.startDate)) + (comment.endDate ? 0 : (this.lineWidth / 2))
        const yPos = this.lineWidth / 2 + (rowNumber + 1) * this.rowHeight
        const line = { startX, endX, startY: yPos, endY: yPos + this.rowHeight, comment }
        lines.push(line)
        ctx.save();
        ctx.strokeStyle = "rgba(255, 165, 0, 1)";
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.lineWidth = this.lineWidth;
        ctx.beginPath();
        ctx.moveTo(max(line.startX, chartArea.left), line.startY);
        ctx.lineTo(min(line.endX, chartArea.right), line.startY);
        ctx.stroke();
        ctx.restore();

        // Draw comment zone borders
        ctx.strokeStyle = "rgba(128, 128, 128, 0.5)";
        ctx.lineWidth = 1;
        ctx.setLineDash([5, 5])
        ctx.beginPath();
        ctx.moveTo(line.startX, chartArea.top);
        ctx.lineTo(line.startX, chartArea.bottom);
        ctx.moveTo(line.endX, chartArea.top);
        ctx.lineTo(line.endX, chartArea.bottom);
        ctx.stroke();
        ctx.closePath();
        ctx.setLineDash([])
        ctx.restore();

        // Fill comment zone
        ctx.beginPath();
        ctx.rect(line.startX, chartArea.top, line.endX - line.startX, chartArea.bottom - chartArea.top);
        ctx.fillStyle = "rgba(128, 128, 128, 0.1)";
        ctx.fill()
        ctx.closePath();
        ctx.restore();
      })
    })
    this.lines = lines
    chart.comments = lines
  },
  beforeLayout(chart) {
    chart.options.layout.padding.top = this.totalCommentHeight;
  },
  afterEvent(chart, args) {
    const pluginOptions = chart.config.options.plugins?.chartCommentPlugin || {};
    if (!pluginOptions.data?.length) return;
    const { event } = args;
    const { x, y } = event;
    chart.canvas.style.cursor = "default";
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    chart.draw()
    for (const line of this.lines) {
      const isWithinLine = x >= line.startX && x <= line.endX && y >= line.startY && y <= line.endY
      if (isWithinLine) {
        // Hover Effects
        chart.canvas.style.cursor = "pointer";
        ctx.strokeStyle = "rgb(255,100,0)";
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.lineWidth = this.lineWidth;
        ctx.beginPath();
        ctx.moveTo(max(line.startX, chartArea.left), line.startY);
        ctx.lineTo(min(line.endX, chartArea.right), line.startY);
        ctx.stroke();
        ctx.restore();

        if (event.type === "click") {
          pluginOptions.onClick && pluginOptions.onClick(line.comment)
        } else {
          // Generate tooltip
          const formatTooltipLines = (ctx, text: string, maxWidth: number, fontSize: number = 12, lineSpacing: number = 4, bold: boolean = false) => {
            const words = text.split(" ");
            ctx.font = `${bold ? "bold" : "normal"} ${fontSize}px Arial`
            let line = "";
            const lines = [];
            words.forEach((word) => {
              const testLine = line + word + " ";
              const testWidth = ctx.measureText(testLine).width;
              if (testWidth > maxWidth && line !== "") {
                lines.push(line.trim());
                line = word + " ";
              } else {
                line = testLine;
              }
            });
            lines.push(line.trim())
            const lineHeight = fontSize + lineSpacing;
            const tooltipHeight = lines.length * lineHeight;
            return { height: tooltipHeight, lines, fontSize, bold };
          }

          const startDate = DateTime.fromISO(line.comment.startDate, { zone: "utc" });
          const endDate = DateTime.fromISO(line.comment.endDate, { zone: "utc" });
          const createdAt = DateTime.fromISO(line.comment.createdAt, { zone: "utc" });
          const tooltipLines = [
            { message: line.comment.content, fontSize: 12, bold: true },
            { message: "", fontSize: 0, lineHeight: 8 },
            ...(line.comment.endDate ? [{ message: "Date Range:", fontSize: 10 }] : []),
            { message: line.comment.endDate ? `${startDate.toFormat("f")} - ${endDate.toFormat("f")}` : `Date: ${startDate.toFormat("f")}`, fontSize: 10, lineHeight: 6 },
            { message: `${createdAt.toRelative()} by ${line.comment.name}`, fontSize: 10 }
          ]
          const gap = 10
          const tooltipTextWidth = 200
          const tooltipBoxWidth = tooltipTextWidth + 2 * gap
          const formattedLines = tooltipLines.map(({ message, fontSize, lineHeight, bold }) => formatTooltipLines(ctx, message, tooltipTextWidth, fontSize, lineHeight, bold))
          const totalHeight = 2 * gap + formattedLines.reduce((total, cur) => total + cur.height, 0)
          const dynamicX = x + tooltipBoxWidth > chartArea.right ? chartArea.right - tooltipBoxWidth : x
          ctx.beginPath();
          ctx.roundRect(dynamicX, y + gap, tooltipBoxWidth, totalHeight, gap);
          ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
          ctx.fill();
          ctx.strokeStyle = "black";
          ctx.lineWidth = 1;
          ctx.stroke();
          ctx.closePath();
          ctx.restore();

          let iterationHeight = 2 * gap;
          formattedLines.forEach(({ height, lines, fontSize, bold }, i) => {
            ctx.font = `${bold ? "bold" : "normal"} ${fontSize}px Arial`
            ctx.fillStyle = "white";
            ctx.textAlign = "left";
            lines.forEach((line) => {
              ctx.fillText(line, dynamicX + gap, y + iterationHeight + gap, tooltipTextWidth);
              iterationHeight += height / lines.length
            })
            ctx.restore();
          })

        }
        break;
      }
    }
  }
}

export default ChartCommentPlugin;
