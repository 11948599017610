import { useMutation, useQuery, UseQueryOptions, UseMutationOptions } from "@tanstack/react-query";
import * as UserRequests from "./service/user";
import {
  ApisUserMetadata,
  CompanyT,
  CompanyUser,
  GuardRoleDefinitionT,
  NotificationSubscriptionT,
  UpdateNotificationSubscriptionRequest,
  UpdateUserSelfRequest,
  UserSelfCompany, VerifyPhoneNumberRequest
} from "@apis/types";
import { queryClient } from "./index";
import { PasswordChangeTicketResponse, User } from "@auth0/types";

////////// QUERIES //////////
export const useGetMetadataSelf = (options?: Omit<UseQueryOptions<ApisUserMetadata>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["user-metadata"],
  queryFn: () => UserRequests.getMetadataSelf(),
  ...options,
  staleTime: 30 * 1000
});

export const useGetRolesSelf = (options?: Omit<UseQueryOptions<GuardRoleDefinitionT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["user-self-roles"],
  queryFn: () => UserRequests.getUserRoleDefinitions(),
  staleTime: 30 * 1000,
  refetchOnMount: false,
  ...options
});

export const useGetCompaniesSelf = (options?: Omit<UseQueryOptions<UserSelfCompany[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["user-self-companies"],
  queryFn: () => UserRequests.getUserCompanies(),
  ...options
});

export const useGetSelfNotificationSubscriptions = (type: "landfill" | "device", resource: string, options?: Omit<UseQueryOptions<NotificationSubscriptionT[]>, "queryKey" | "queryFn">) => useQuery({
  queryKey: ["subs", type, resource],
  queryFn: () => UserRequests.getUserNotificationSubscriptions(type, resource),
  ...options
});



////////// MUTATIONS //////////
export const useUpdateProfileSelf = (options?: UseMutationOptions<User, Error, UpdateUserSelfRequest>) => useMutation({
  mutationKey: ["update-profile-self"],
  mutationFn: (body) => UserRequests.updateProfileSelf(body),
  ...options
});

export const useUpdatePasswordSelf = (options?: UseMutationOptions<PasswordChangeTicketResponse>) => useMutation({
  mutationKey: ["update-password-self"],
  mutationFn: () => UserRequests.updatePasswordSelf(),
  ...options
});

export const useUpdateMetadataSelf = (options?: UseMutationOptions<ApisUserMetadata, Error, Partial<ApisUserMetadata>>) => useMutation({
  mutationKey: ["update-user-metadata"],
  mutationFn: (body) => UserRequests.updateMetadataSelf(body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["user-metadata"], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useAcceptCompanyInvitation = (options?: UseMutationOptions<CompanyUser, Error, string>) => useMutation({
  mutationKey: ["accept-company-invitation"],
  mutationFn: (companyID) => UserRequests.acceptCompanyInvitation(companyID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["user-self-companies"], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useLeaveCompany = (options?: UseMutationOptions<CompanyT, Error, string>) => useMutation({
  mutationKey: ["leave-company"],
  mutationFn: (companyID) => UserRequests.leaveCompany(companyID),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["user-self-companies"], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  }
});

export const useUpdateSelfNotificationSubscriptions = (type: "landfill" | "device", resource: string, options?: UseMutationOptions<NotificationSubscriptionT, Error, UpdateNotificationSubscriptionRequest>) => useMutation({
  mutationKey: ["subs", type, resource],
  mutationFn: (body) => UserRequests.updateUserNotificationSubscription(type, resource, body),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["subs", type, resource], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  },
});

export const useStartPhoneVerification = (options?: UseMutationOptions<{ message: string }, Error, VerifyPhoneNumberRequest>) => useMutation({
  mutationFn: (body) => UserRequests.startPhoneVerification(body),
  ...options
});

export const useCompletePhoneVerification = (options?: UseMutationOptions<{ message: string }, Error, string>) => useMutation({
  mutationFn: (code) => UserRequests.completePhoneVerification(code),
  ...options,
  onSuccess: (...args) => {
    queryClient.refetchQueries({ queryKey: ["user-metadata"], exact: true })
    options?.onSuccess && options?.onSuccess(...args)
  },
});
